import { Grid, useMediaQuery } from '@mui/material';

import { ReactElement } from 'react';
import logo from '../../images/logos/logo-large-black.png';

const LogoBanner = (): ReactElement => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const isMediumScreen = useMediaQuery('(max-width: 960px)');
  const isLargeScreen = useMediaQuery('(max-width: 1280px)');

  const getLogoSize = () => {
    if (isSmallScreen) {
      return '150';
    } else if (isMediumScreen) {
      return '175';
    } else if (isLargeScreen) {
      return '200';
    } else {
      return '250';
    }
  };

  return (
    <Grid
      container
      spacing={0}
      justifyContent="left"
      alignItems="center"
      sx={{
        padding: '0 0 20px 20px',
        '@media (min-width: 600px)': {
          padding: '0 0 70px 20px',
        },
        '@media (min-width: 960px)': {
          padding: '0 0 70px 50px',
        },
        '@media (min-width: 1280px)': {
          padding: '0 0 70px 100px',
        },
      }}
    >
      <Grid item>
        <img src={logo} width={getLogoSize()} alt="Serverless Advocate Logo" />
      </Grid>
    </Grid>
  );
};

export default LogoBanner;
