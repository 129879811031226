import {
  AppBar,
  Box,
  Grid,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';

const NewsBar = () => {
  const theme = useTheme();
  const backgroundColor = theme.palette.background.default;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        variant="outlined"
        sx={{ backgroundColor: backgroundColor }}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <Typography variant="body2" color={'primary'}>
                <CelebrationTwoToneIcon fontSize="small" /> Enterprise
                Serverless Book Coming 12/2024
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NewsBar;
