import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../../images/marketing/hero-pane-about.jpg';

const StyledImage = styled('img')({
  margin: '0 0 -6px 0',
  padding: '30px 0 0 0',
});

const HeroPaneAbout = (): JSX.Element => {
  return (
    <div>
      <Grid container spacing={0} justifyContent="center" alignItems="baseline">
        <Grid item xs={12} style={{ width: '100%' }}>
          <StyledImage
            src={logo}
            alt="Serverless Advocate Logo"
            width={'95%'}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroPaneAbout;
