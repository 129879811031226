import { Button, Grid } from '@mui/material';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CloudDone from '@mui/icons-material/CloudDone';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../shared/scroll-to-top';

const GradientText = styled('h1')(() => ({
  fontFamily: 'Poppins, Roboto, sans-serif',
  marginTop: 0,
  marginBottom: 0,
  fontWeight: '800',
  background:
    'linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontSize: '10vw',
  '@media (min-width: 600px)': {
    fontSize: '10vw',
  },
  '@media (min-width: 960px)': {
    fontSize: '8vw',
  },
  '@media (min-width: 1280px)': {
    fontSize: '6vw',
  },
}));

const StyledCloudDone = styled(CloudDone)({
  color: '#32B79C',
  fontSize: '10vw',
  '@media (min-width: 300px)': {
    fontSize: '12vw',
    margin: '0 0 -6px 10px',
  },
  '@media (min-width: 600px)': {
    fontSize: '10vw',
    margin: '0 0 -10px 15px',
  },
  '@media (min-width: 960px)': {
    fontSize: '8vw',
    margin: '0 0 -15px 20px',
  },
  '@media (min-width: 1280px)': {
    fontSize: '6vw',
    margin: '0 0 -15px 20px',
  },
});

const SubTitle = styled('p')(() => ({
  fontFamily: 'Roboto, sans-serif',
  fontWeight: '300',
  fontSize: '4vw',
  marginTop: 0,
  '@media (min-width: 600px)': {
    fontSize: '3vw',
  },
  '@media (min-width: 960px)': {
    fontSize: '2vw',
  },
  '@media (min-width: 1280px)': {
    fontSize: '1.5vw',
  },
}));

const useStyles = {
  container: {
    flexGrow: 1,
    minHeight: '300px',
    textAlign: 'center' as const,
  },
};

const HeroPane = (): JSX.Element => {
  return (
    <div style={useStyles.container}>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '0 20px',
          '@media (min-width: 600px)': {
            padding: '0 20px',
          },
          '@media (min-width: 960px)': {
            padding: '0 50px',
          },
          '@media (min-width: 1280px)': {
            padding: '0 100px',
          },
        }}
      >
        <Grid item xs={12} style={{ width: '100%', textAlign: 'left' }}>
          <GradientText>
            Empowering Others Through Serverless Knowledge
            <StyledCloudDone />
          </GradientText>
        </Grid>
        <Grid item xs={12} style={{ width: '100%', textAlign: 'left' }}>
          <SubTitle>
            Specialising in assisting a broad spectrum of clients, ranging from
            innovative startups to established enterprise organizations.
          </SubTitle>
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Button
            onClick={scrollToTop}
            variant="outlined"
            component={Link}
            to="/services"
            size="large"
            sx={{ marginRight: '8px' }}
            color="secondary"
            startIcon={<ArrowOutwardIcon />}
          >
            Services
          </Button>
          <Button
            onClick={scrollToTop}
            variant="outlined"
            component={Link}
            to="/contact"
            size="large"
            color="secondary"
            startIcon={<ArrowOutwardIcon />}
          >
            Contact
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default HeroPane;
