import { AppBar, Box, Button, Grid, Toolbar, useTheme } from '@mui/material';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../shared/scroll-to-top';

const ButtonStyled = styled(Button)(() => ({
  fontSize: '10px',
  '@media (min-width: 300px)': {
    fontSize: '12px',
  },
  '@media (min-width: 390px)': {
    fontSize: '14px',
  },
  '@media (min-width: 600px)': {
    fontSize: '16px',
  },
  '@media (min-width: 800px)': {
    fontSize: '20px',
  },
})) as typeof Button;

const NavBar = () => {
  const theme = useTheme();
  const backgroundColor = theme.palette.background.default;

  const buttonStyle = {
    color: theme.palette.text.secondary,
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: { backgroundColor }, boxShadow: 'none' }}
      >
        <Toolbar>
          <Grid container justifyContent="right" alignItems="center">
            <Grid item>
              <ButtonStyled
                onClick={scrollToTop}
                component={Link}
                size="small"
                to="/"
                color="secondary"
                style={buttonStyle}
              >
                Home.
              </ButtonStyled>
              <ButtonStyled
                onClick={scrollToTop}
                component={Link}
                to="/services"
                color="secondary"
                size="small"
                style={buttonStyle}
              >
                Services.
              </ButtonStyled>
              <ButtonStyled
                onClick={scrollToTop}
                component={Link}
                to="/patterns"
                color="secondary"
                size="small"
                style={buttonStyle}
              >
                Patterns.
              </ButtonStyled>
              <ButtonStyled
                href="https://blog.serverlessadvocate.com/"
                target="_blank"
                color="secondary"
                size="small"
                style={buttonStyle}
              >
                Blog.
              </ButtonStyled>
              <ButtonStyled
                onClick={scrollToTop}
                variant="outlined"
                component={Link}
                size="small"
                to="/contact"
                color="primary"
                startIcon={<ArrowOutwardIcon />}
              >
                Contact
              </ButtonStyled>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
